<template>
    <div class="tag-manage-view">
        <PageTitle name="标签管理"></PageTitle>
        <div class="common-ctrl-bar">
            <div class="label">Tag Key：</div>
            <n-input
                v-model:value="filterTagKey"
                size="small"
                class="input"
                placeholder="筛选Key(区分大小写)"
                style="width: 200px"
            ></n-input>
            <div class="label">Tag Value：</div>
            <n-input
                v-model:value="filterTagValue"
                size="small"
                class="input"
                placeholder="筛选Value(区分大小写)"
                style="width: 200px"
            ></n-input>
            <n-button
                type="primary"
                size="small"
                style="margin-left: auto"
                @click="showTagEditor = true"
                >新建标签</n-button
            >
        </div>
        <n-data-table
            class="content"
            :columns="columns"
            :data="filteredTableData"
            :loading="loading"
            :row-key="row => JSON.stringify({ key: row.key, value: row.value })"
        ></n-data-table>
        <n-drawer
            v-model:show="showTagEditor"
            :width="800"
            @update:show="hideEditor"
        >
            <n-drawer-content :title="(isUpdate ? '编辑' : '新建') + '标签'">
                <n-form
                    ref="tagEditFormRef"
                    :rules="tagEditFormRules"
                    :model="tagEditForm"
                >
                    <n-form-item path="key" label="Tag Key">
                        <n-input
                            v-model:value="tagEditForm.key"
                            :disabled="isUpdate"
                        ></n-input>
                    </n-form-item>
                    <n-form-item path="value" label="Tag Value">
                        <n-input
                            v-model:value="tagEditForm.value"
                            :disabled="isUpdate"
                        ></n-input>
                    </n-form-item>
                    <n-form-item path="teamGroup" label="TeamGroup">
                        <n-dynamic-tags v-model:value="teamGroupTags">
                            <template #input="{ deactivate }">
                                <TeamGroupAdd
                                    :tag-data="tableData"
                                    :disabled-team-data="tagEditForm.teamGroup"
                                    @hide="deactivate"
                                    @complete="
                                        items => {
                                            appendTeamGroup(items);
                                            deactivate();
                                        }
                                    "
                                ></TeamGroupAdd>
                            </template>
                            <template #trigger="{ activate }">
                                <n-button size="small" @click="activate"
                                    >添加</n-button
                                >
                                <n-button size="small" @click="clearTeamGroup"
                                    >清空</n-button
                                >
                            </template>
                        </n-dynamic-tags>
                    </n-form-item>
                </n-form>
                <template #footer>
                    <n-button
                        type="primary"
                        @click="tagEditFormSubmit"
                        :loading="submitLoading"
                        >提交</n-button
                    >
                </template>
            </n-drawer-content>
        </n-drawer>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.tag-manage-view {
    .common-content;

    .content {
        margin-top: 10px;
        background-color: #fff;
    }
    min-height: 90vh;
}
</style>

<script setup>
import { ref, h, computed } from 'vue';
import { useRoute } from 'vue-router';
import {
    NButton,
    NDataTable,
    NDrawer,
    NDrawerContent,
    NForm,
    NFormItem,
    NInput,
    NDynamicTags,
    useMessage,
} from 'naive-ui';

import PageTitle from '@/components/PageTitle';

import TeamGroupAdd from './components/TeamGroupAdd';

import CtrlCell from '@/components/CtrlCell';

import { MVBizViewAPI } from '@/common/API';

const route = useRoute();

const message = useMessage();

let filterTagKey = ref('');
let filterTagValue = ref('');

let columns = ref([
    { title: 'Tag Key', key: 'key', width: 200 },
    { title: 'Tag Value', key: 'value', width: 200 },
    {
        title: 'Team&Group',
        render(row) {
            return row.list.map(item => item.team + ':' + item.group).join(',');
        },
        ellipsis: true,
    },
    {
        title: '操作',
        key: '',
        width: 80,
        render(row) {
            return h(CtrlCell, {
                row,
                onEdit: item => {
                    showTagEditor.value = true;
                    setEditTag(item);
                },
                onDelete: item => {
                    deleteTag(item);
                },
            });
        },
        align: 'center',
    },
]);

let tableData = ref([]);

let filteredTableData = computed(() => {
    let filteredData = tableData.value;
    if (filterTagKey.value) {
        filteredData = filteredData.filter(
            item => item.key.indexOf(filterTagKey.value) > -1
        );
    }
    if (filterTagValue.value) {
        filteredData = filteredData.filter(
            item => item.value.indexOf(filterTagValue.value) > -1
        );
    }
    return filteredData;
});

let showTagEditor = ref(false);

let isUpdate = ref(false);

let tagEditFormRef = ref();

let tagEditFormRules = ref({
    key: {
        require: true,
        trigger: 'blur',
        validator: () => {
            return !!tagEditForm.value.key || new Error('请填写Tag Key');
        },
    },
    value: {
        require: true,
        trigger: 'blur',
        validator,
    },
});

let tagEditForm = ref({
    key: '',
    value: '',
    teamGroup: [],
});

let teamGroupTags = computed({
    get: () =>
        tagEditForm.value.teamGroup.map(item => ({
            label: `${item.team}:${item.group}`,
            value: item,
        })),
    set: value => {
        tagEditForm.value.teamGroup = value.map(item => item.value);
    },
});

function validator() {
    let { key, value } = tagEditForm.value;
    if (!value) {
        return new Error('请填写Tag Value');
    }
    if (!key) {
        return new Error('请填写上方Tag Key');
    }
    if (isUpdate) {
        return true;
    }
    return new Promise((resolve, reject) => {
        MVBizViewAPI.checkTagExist(key, value).then(res => {
            if (res.error_no !== 0) {
                resolve();
                return;
            }
            if (res.data === true) {
                reject('该Key和Value已经存在');
                return;
            }
            resolve();
        });
    });
}

function setEditTag(item) {
    isUpdate.value = true;
    tagEditForm.value = {
        key: item.key,
        value: item.value,
        teamGroup: item.list,
    };
}

function appendTeamGroup(items) {
    tagEditForm.value.teamGroup.push(...items);
}

function clearTeamGroup(e) {
    e.preventDefault();
    tagEditForm.value.teamGroup = [];
}

let submitLoading = ref(false);

function tagEditFormSubmit(e) {
    e.preventDefault();
    tagEditFormRef.value
        .validate(errors => {
            if (errors) {
                return;
            }
            submitLoading.value = true;
            let func = isUpdate.value
                ? MVBizViewAPI.updateTag
                : MVBizViewAPI.createTag;
            let payload = {
                tree_type: route.params.model,
                key: tagEditForm.value.key,
                value: tagEditForm.value.value,
                list: Array.from(
                    new Set(
                        tagEditForm.value.teamGroup.map(item => item.node_id)
                    )
                ),
            };
            func(payload)
                .then(res => {
                    submitLoading.value = false;
                    if (res.error_no !== 0) {
                        message.error(res.error_msg || '未知异常导致加载失败');
                        return;
                    }
                    message.success('操作完成');
                    loadData();
                    setTimeout(() => {
                        showTagEditor.value = false;
                    }, 500);
                })
                .catch(err => {
                    submitLoading.value = false;
                    console.log(err);
                    message.error('未知异常导致加载失败');
                });
        })
        .catch(err => {
            console.log(err);
        });
}

function deleteTag(row) {
    message.loading('操作中...');
    MVBizViewAPI.deleteTag(route.params.model, row.key, row.value).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致操作失败');
            return;
        }
        message.success('操作完成');
        loadData();
    });
}

function hideEditor(value) {
    if (value) {
        return;
    }
    isUpdate.value = false;
    tagEditForm.value = { key: '', value: '', teamGroup: [] };
}

let loading = ref(false);

function loadData() {
    loading.value = true;
    MVBizViewAPI.getTagMList(route.params.model).then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            return;
        }
        tableData.value = res.data;
    });
}

loadData();
</script>
