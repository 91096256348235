<template>
    <div class="team-group-add-view">
        <n-tabs type="segment" size="tiny">
            <n-tab-pane name="关键字查找TeamGroup">
                <n-input
                    v-model:value="teamGroupKeyword"
                    placeholder="输入关键字按Enter查询TeamGroup"
                    size="small"
                    style="margin-top: 4px"
                    @keyup="onTeamGroupKeywordKeyup"
                >
                    <template #prefix>
                        <n-icon>
                            <SearchFilled />
                        </n-icon>
                    </template>
                </n-input>
                <n-data-table
                    style="margin-top: 4px"
                    :columns="teamGroupColumns"
                    :data="teamGroupTableData"
                    :loading="teamGroupLoading"
                    :row-key="row => JSON.stringify(row)"
                    size="tiny"
                    :max-height="200"
                    @update:checked-row-keys="onTeamGroupSelect"
                ></n-data-table>
            </n-tab-pane>
            <n-tab-pane name="抽取已有Tag中的TeamGroup">
                <n-input
                    v-model:value="tagKeyword"
                    placeholder="输入关键字查询已有Tag"
                    size="small"
                    style="margin-top: 4px"
                >
                    <template #prefix>
                        <n-icon>
                            <SearchFilled />
                        </n-icon>
                    </template>
                </n-input>
                <n-data-table
                    style="margin-top: 4px"
                    :columns="tagColumns"
                    :data="tagTableData"
                    :row-key="
                        row =>
                            JSON.stringify({ key: row.key, value: row.value })
                    "
                    size="tiny"
                    :max-height="200"
                    @update:checked-row-keys="onTagSelect"
                ></n-data-table>
            </n-tab-pane>
        </n-tabs>
        <div class="footer">
            <n-button size="tiny" type="primary" @click="complete"
                >添加</n-button
            >
        </div>
    </div>
</template>

<style lang="less" scoped>
.team-group-add-view {
    border: 1px solid #eee;
    border-radius: 3px;
    width: 730px;
    padding: 10px;

    .footer {
        padding: 4px 0 0;
        text-align: right;
    }
}
</style>

<script setup>
import { ref, computed, defineEmits, defineProps } from 'vue';

import { useRoute } from 'vue-router';

import { NTabs, NTabPane, NInput, NButton, NDataTable, NIcon } from 'naive-ui';

import { SearchFilled } from '@vicons/material';

import { MVBizViewAPI } from '@/common/API';

const props = defineProps({
    tagData: {
        type: Array,
        default() {
            return [];
        },
    },
    disabledTeamData: {
        type: Array,
        default() {
            return [];
        },
    },
});

const route = useRoute();

const emits = defineEmits(['complete']);

let selectedTeamGroups = ref([]);

let teamGroupKeyword = ref('');

let teamGroupColumns = ref([
    {
        type: 'selection',
        disabled(row) {
            return (
                props.disabledTeamData.filter(
                    item => item.node_id === row.node_id
                ).length > 0
            );
        },
    },
    {
        title: 'Team',
        key: 'team',
    },
    {
        title: 'Group',
        key: 'group',
    },
]);

let teamGroupTableData = ref([]);

function onTeamGroupSelect(rowKeys) {
    selectedTeamGroups.value = rowKeys.map(rowKey => JSON.parse(rowKey));
}

let tagColumns = ref([
    {
        type: 'selection',
    },
    {
        key: 'key',
        title: 'Tag Key',
    },
    {
        key: 'value',
        title: 'Tag Value',
    },
]);

let tagTableData = computed(() => {
    if (!tagKeyword.value) {
        return [];
    }
    let res = props.tagData.filter(
        item => `${item.key} ${item.value}`.indexOf(tagKeyword.value) > -1
    );
    return res;
});

let teamGroupLoading = ref(false);

function onTeamGroupKeywordKeyup(e) {
    if (e.code === 'Enter') {
        loadTeamGroupData();
    }
}

function loadTeamGroupData() {
    if (!teamGroupKeyword.value) {
        return;
    }
    teamGroupLoading.value = true;
    MVBizViewAPI.getTreeNodes(route.params.model, teamGroupKeyword.value).then(
        res => {
            teamGroupLoading.value = false;
            if (res.error_no !== 0) {
                teamGroupTableData.value = [];
                return;
            }
            teamGroupTableData.value = res.data;
        }
    );
}

let tagKeyword = ref('');

function onTagSelect(rowKeys) {
    let teamGroups = [];
    rowKeys.forEach(row => {
        let obj = JSON.parse(row);
        let selectItem = tagTableData.value.find(
            item => item.key === obj.key && item.value === obj.value
        );
        teamGroups.push(...selectItem.list);
    });
    selectedTeamGroups.value = teamGroups;
}

function complete() {
    emits('complete', selectedTeamGroups.value);
}
</script>
